.package-list-container {
    margin-left: 1rem;
    width: 100%;
    box-shadow: 0 10px 10px -10px #dedede;
    border-radius: 10px;
    background: #fff;
}

.package-list-container table {
    width: 100%;
    text-align: center;
    border-spacing: 0px;
    border-collapse: separate;
}

.package-list-container table thead {
    background: rgba(235, 242, 253, 1);
    width: 100%;
    border-radius: 10px;
}

.package-list-container table thead tr th {
    font-size: 16px;
    font-family: "Montserrat";
    font-weight: 600;
    padding: 10px;
}

.package-list-container table tr td {
    padding: 10px;
    border-bottom: 1px solid rgb(166, 169, 177, 0.5);
    font-size: 14px;
}

.package-list-container table tbody tr:last-child td {
    border-bottom: none;
}

.package-list-container button {
    border: none;
    background: none;
    outline: none;
    color: #00081d;
    font-size: 20px;
    cursor: pointer;
}

#pending {
    background: rgba(255, 230, 200, 1);
    /* color: #FFBB38; */
    color: #FF8C00;
    font-weight: 600;
    padding: 2px 10px;
    border-radius: 20px;
    margin: auto;
}

#on-hold {
    background: rgba(212, 223, 255, 1);
    color: rgba(57, 106, 255, 1);
    font-weight: 600;
    padding: 2px 10px;
    border-radius: 20px;
    margin: auto;
}

#Awaiting_Payment {
    background: rgba(255, 245, 217, 1);
    color: rgba(255, 187, 56, 1);
    font-weight: 600;
    padding: 2px 10px;
    border-radius: 20px;
    margin: auto;
    height: 24px;
    overflow: hidden;
}

#confirm {
    background: rgba(188, 255, 224, 1);
    color: rgba(18, 183, 106, 1);
    font-weight: 600;
    padding: 2px 10px;
    border-radius: 20px;
    margin: auto;
}

#rejected {
    background: rgba(255, 0, 0, 0.5);
    color: rgba(255, 0, 0, 1);
    font-weight: 600;
    padding: 2px 10px;
    border-radius: 20px;
    margin: auto;
}

/* -------------------------------Booking Details page-------------- */
.booking-details-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 8, 29, 0.6);
    z-index: 2;
    overflow: auto;
}

.booking-details-page {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: fit-content;
    width: 70%;
    background: #fff;
    margin-left: 6%;
    border-radius: 10px;
    overflow-y: auto;
}

.booking-pkg-pass-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background: #EBF2FD;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.booking-pkg-pass-header h4 {
    font-size: 20px;
    color: #1e2f58;
    font-weight: 600;
    font-family: "Montserrat";
}

.booking-pkg-pass-header h2 {
    color: #00081d;
    cursor: pointer;
}

.booking-pkg-pass-details {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    padding: 0 2rem;
}

.booking-pkg-pass-name {
    text-align: center;
}

.booking-pkg-package-details {
    padding: 1rem 2rem;
    display: flex;
    font-family: "Montserrat";
}

.booking-pkg-package-details h2 {
    font-size: 20px;
    color: #2f4477;
}

.booking-pkg-agent-details {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    padding: 0 2rem;
}

.booking-pkg-agent-name {
    text-align: center;
}

.payment-select {
    width: 200px;
    font-size: 16px;
    color: #00081d;
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 20px;
}

.booking-pkg-price-and-actions {
    margin-top: 1rem;
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    align-items: center;
    background: #EBF2FD;
    padding: 1rem 2rem;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.booking-pkg-price h2 {
    color: #001E6D;
    font-size: 20px;
}

.booking-pkg-actions {
    display: flex;
    gap: 1rem;
}

.booking-pkg-actions button {
    border: none;
    background: none;
    outline: none;
    font-weight: 600;
    font-size: 16px;
    font-family: "Montserrat";
    cursor: pointer;
}
.intensity {
    opacity: 0.3;
}

.booking-pkg-actions #reject {
    border: 1px solid red;
    color: red;
    border-radius: 20px;
    padding: 8px 20px;
}

.booking-pkg-actions #on-hold {
    border: 1px solid orange;
    color: orange;
    border-radius: 20px;
    padding: 8px 20px;
}

.booking-pkg-actions #send-link {
    border: 1px solid green;
    color: green;
    border-radius: 20px;
    padding: 8px 20px;
}