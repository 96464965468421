.css-128yt6l-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer{
    padding: 0 1rem;
}

.css-128yt6l-MuiDataGrid-root .MuiDataGrid-cell {
    padding: 0 1rem;
}

.css-128yt6l-MuiDataGrid-root .MuiDataGrid-cellContent {
    padding: 0 1rem;
 
}