.agnet-header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.agent-container-parent {
    border-radius: 10px;
    padding: 1rem;
}
.search-from-list {
    border:1px solid skyblue;
    display:flex;
    align-items:center;
    padding:5px 10px;
    border-radius:20px;
}
.search-from-list input {
    border: none;
    outline: none;
    background: none;
}
.agent-header-button {
    display: flex;
    gap: 10px;
}
.agent-header-button button {
    border: none;
    outline: none;
    background: rgba(226, 235, 255, 1);
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 16px;
    color: rgba(46, 144, 250, 1);
    cursor: pointer;
}
.agent-table table {
    width: 100%;
    text-align: center;
    background: #fff;
    border-spacing: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius:10px ;
    box-shadow:0 10px 10px -10px #dedede;
    /* padding: 0 20px; */
}
.agent-table table thead {
    background: rgba(235, 242, 253, 1);
}
.agent-table table thead tr td {
    padding: 10px;
    font-weight: 600;
    font-size: 14px;
    
}
.agent-table table tbody {
    padding: 0 20px;
}
.agent-table table tbody tr td {
    font-size: 14px;
    padding: 10px;
    border-bottom: 1px solid #dedede;
    /* border-bottom: 1px solid rgb(166, 169, 177, 0.5); */
}
.agent-table table tbody tr:last-child td {
    /* border-bottom: none;  */
}
/* ---------------------Agent Details page----------------------- */
.agent-img-and-details {
    display: flex;
    gap: 1rem;
    padding: 1rem ;
}
.agent-details{
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
}
.agent-sub-details h4 {
    color: #A3A8B8;
    font-size: 16px;
    font-weight: 500;
}
.agent-sub-details h3 {
    color:#1D3071;
    font-size: 16px;
    font-weight: 500;
}
.agent-sales-records {
    padding-left: 7rem;
}
.agent-sales-records h2 {
   color:#1D3071;
   font-size: 18px;
   font-weight: 600;
}
.agent-delete-btn {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 2rem;
    
}
.agent-delete-btn button {
    display: flex;
    gap: 6px;
    color: #1D3071;
    font-weight: 600;
    align-items: center;
    border: 1.5px solid #2E90FA;
    border-radius: 20px;
    background: none;
    padding: 5px 15px;
    cursor: pointer;
}