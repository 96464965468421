.css-1y7coo4-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    border: none !important; 
    background-color: #fff !important; 
}
.css-1y7coo4-MuiButtonBase-root-MuiPaginationItem-root {
   border: none !important;
}
.css-1y7coo4-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #fff !important;
}
.MuiPagination-ul .css-wjh20t-MuiPagination-ul {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
#download-btn {
    position: absolute;
    /* top: 120px; */
    right: 18%;
    border: 1px solid skyblue;
    background: none;
    padding: 6px 10px;
    border-radius: 20px;
    cursor: pointer;
    color: rgba(46, 144, 250, 1);
    margin-top: 22px;
}
.not-found-booking {
  padding: 0 0 1rem 2rem;
  background: #fff;
  margin-left: 1rem;
  width: 100%;
}
.not-found-booking h2 {
    font-weight: 500;
}