.price-description-input textarea {
    border: 1.5px solid rgba(35, 35, 35, 0.2);
    background: #fff;
    outline: none;
    padding: 10px 20px;
    border-radius: 20px;
    width: 100%;
    overflow: hidden;
}
.package-form-from-country-attraction {
    padding: 1rem 4rem;
}
.package-form-from-country-attraction h2 {
    color: #001849;
    font-size: 16px;
    margin-bottom: 10px;
}
.package-form-from-country-attraction select {
    border: 1.5px solid rgba(35, 35, 35, 0.2);
    border-radius: 20px;
    padding: 8px 20px;
    width: 40%;
    outline: none;
}
#attraction-price-delete {
    font-size: 30px;
    margin-top: 2rem;
    margin-left: -2rem;
    cursor: pointer;
}