.dashboard-container {
  margin-left: 20px;
  font-family: "Montserrat";
  padding-top: 1rem;
  padding-bottom: 2rem;
}
.dashboard-container h2 {
    color: rgba(32, 34, 36, 0.8);
    font-size: 20px;
    font-weight: 600;
}
.dashboard-all-details {
    padding: 1rem;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 10px;
}
.dashboard-agent-parent {
    background: #fff;
    padding: 1rem;
    border-radius: 10px;
}
.dashboard-agent {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.dashboard-agent-detail h4 {
    color: rgba(32, 34, 36, 0.8);
    font-size: 15px;
    font-weight: 500;
}
.dashboard-inc-text {
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 14px;
}
.dashboard-graph-recent-booking {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
  padding: 0 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.dashboard-booking-pending h4 {
    font-weight: 600;
}
.dashboard-graph {
  border-radius: 10px;
  background: #fff;
  padding: 1rem;
}
.dashboard-graph-header {
    display: flex;
    justify-content: space-between;
}
.dashboard-graph-header select {
    width: fit-content;
    height: 25px;
    font-size: 14px;
    color:rgba(43, 48, 52, 0.6);
    border: 1px solid rgba(43, 48, 52, 0.4);
    border-radius: 10px;
    padding: 0 10px;
    outline: none;
}
.dashboard-graph-header select option {
    font-size: 14px;
    border-radius: 0px;
    outline: none;
}
.dashboard-graph-header select option:hover {
   background: #ccc;
}

.dashboard-graph-a-m-w {
    display: flex;
    gap: 1rem;
    padding: 10px 0;
}
.dashboard-graph-a-m-w .active {
    border-bottom: 2px solid rgba(15, 10, 222,0.5);
}
.dashboard-graph-a-m-w p {
    color: #00008B;
    font-size: 14px;
    cursor: pointer;
}
.dashboard-graph-container p {
    color: rgba(15, 10, 222,0.8);
}
.dashboard-graph-container .right-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
}
.dashboard-recent-booking {
    background: #fff;
    padding: 1rem;
    border-radius: 10px;
    /* height: fit-content; */
}
.view-all-booking {
    text-align: center;
    padding-top: 30px;
}
.view-all-booking button {
    border: 1px solid rgba(46, 144, 250, 1);
    color: rgba(46, 144, 250, 1);
    outline: none;
    border-radius: 20px;
    background: none;
    padding:5px 30px;
    font-weight: 600;
    cursor: pointer;
}
.dashboard-graph-recent-booking h2 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
}
.dashboard-booking-pending {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    gap: 5px;
    align-items: center;
}
.dashboard-booking-pending p {
    font-size: 12px;
}
/* ------------------------custom graph css----------------------------  */
.dashboard-from-to-date-container {
    box-shadow: 0 0 10px #dedede;
    border: 1px solid #ccc;
    padding:10px;
}
.dashboard-custom-clear {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.dashboard-from-to-date {
    padding:0 1rem;
}
.dashboard-from-to-date h2 {
     margin: 0;
     font-size: 16px;
     font-weight: 600;
     color: #00008B;
}
.dashboard-from-to-date input {
  border: 1px solid skyblue;
  border-radius: 20px;
  padding: 5px 10px;
  color: #00008B;
}
.dashboard-from-date {
  margin-bottom: 10px;
}
.dashboard-to-date {
    margin-bottom: 30px;
}
.dashboard-graph-apply-btn {
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
    margin-bottom: 1rem;
}
.dashboard-graph-apply-btn button {
    background: rgba(46, 144, 250, 1);
    padding: 5px 10px;
    outline: none;
    border: none;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
}
.dashboard-bookingStatus-and-price {
    text-align: center;
}
.dashboard-bookingStatus-and-price p {
     width: 100px;
}
#dashboard-recent-booking-price {
    font-weight: bold;
    margin-top: 5px;
}
.dashboard-recent-booking-title {
    width: 160px;
}