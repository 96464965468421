* {
    font-family: "Montserrat";
}

.package-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
}

.package-header h2 {
    font-size: 18px;
}

.package-header button {
    border: none;
    outline: none;
    color: #fff;
    font-size: 14px;
    padding: 6px 16px;
    background: rgba(46, 144, 250, 1);
    border-radius: 20px;
    cursor: pointer;
}

.package-search-table {
    background: #fff;
    border-radius: 10px;
    margin: 5px 20px 15px;
    padding-top: 10px;
}

.package-search {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    margin-left: 1rem;
    border: 1px solid skyblue;
    border-radius: 20px;
    width: fit-content;
    padding: 5px 20px;
}

.package-search input {
    border: none;
    outline: none;
    background: none;
}

.package-table table {
    width: 100%;
    border-spacing: 0px;
    text-align: center;
}

.package-table b {
    font-weight: 500;
}

.package-table table thead {
    background: rgba(235, 242, 253, 1);
    padding: 10px;
}

.package-table table thead tr th {
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
}

.package-table table tr td {
    padding: 10px;
    border-bottom: 1px solid rgb(166, 169, 177, 0.5);
    font-size: 14px;
}

.package-table table tbody tr:last-child td {
    border-bottom: none;
}

.package-table .package-image {
    width: 70px;
    height: 70px;
}

.package-table .package-image img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
    object-fit: cover;
}

.package-table .package-title {
    width: 180px;
    /* height: 35px; */
    margin: auto;
}
#active-inactive {
    display: none;
}
#active-input {
    width: fit-content;
    cursor: pointer;
}
#active-input input {
    cursor: pointer;
}
#active-input:hover #active-inactive {
   display: block;
   position: absolute;
   margin-left: 10px;
   margin-top: 0px;
   box-shadow: 0 0 5px 5px rgb(38, 38, 38, 0.5);
   padding: 2px 10px;
   border-radius: 5px;
}
/* -----------------------------Package details page css--------------- */
.admin-package-detail-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 8, 29, 0.5);
    z-index: 9999;
}

.admin-package-detail-page {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 90%;
    width: 90%;
    background: #fff;
    border-radius: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}
/* ::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
} */

.back-and-text {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 2rem;
}

.back {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.back-and-text h2 {
    font-size: 18px;
}

.package-basic-details {
    padding: 1rem 2rem 2rem 4rem;
}

.package-basic-details h3 {
    font-size: 16px;
    margin-bottom: 10px;
    color: rgba(0, 24, 73, 1);
}

.admin-pkg-title-description {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    padding: 10px 2rem;
}
.title {
    display: flex;
    gap: 1rem;
    /* width: 50%; */
    width: fit-content;
}
.admin-pkg-title-description-pic {
    display: flex;
    gap: 2rem;
    padding: 10px 2rem;
}

.admin-package-detail-container h5 {
    overflow: auto;
    color: rgba(132, 132, 132, 1);
}

.admin-package-detail-container h4 {
    color: rgba(0, 8, 29, 1);
}

.admin-package-detail-container img {
    border-radius: 8px;
}

.location-details {
    display: flex;
    gap: 1rem;
    align-items: baseline;
}

.location-details ol p,
h6 {
    color: #848484;
    font-weight: 500;
    font-size: 12px;
}

.package-price {
    display: flex;
    gap: 4rem;
    padding: 0 2rem 1rem 4rem;
}

.package-price h3 {
    color: #848484;
}

.package-price h4 {
    color: #001849;
}

.package-delete-edit-btn {
    padding: 0 2rem 1rem 4rem;
    display: flex;
    gap: 2rem;
}

.package-delete-edit-btn button {
    border: 1px solid #2E90FA;
    outline: none;
    background: none;
    padding: 5px 15px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 2px;
    cursor: pointer;
}

#delete {
    color: #2E90FA;
    padding: 6px 20px;
}

#edit {
    background: #2E90FA;
    color: #fff;
    padding: 6px 20px;
    font-weight: 600;
}

/* --------------------------------Package Form------------------------ */
.package-form-title-description {
    display: flex;
    gap: 4rem;
}
.package-form-title {
    width: 30%;
}
.package-form-description {
    width: 50%;
}
.admin-package-detail-page label {
    display: block;
    color: #00213A;
    font-size: 16px;
    margin-bottom: 10px;
}
.package-form-title-description input,textarea {
    border: 1.5px solid rgba(35, 35, 35, 0.2);
    background: #fff;
    outline: none;
    padding: 10px 20px;
    border-radius: 20px;
    width: 100%;
    overflow: hidden;
}
.package-form-cover-banner-img {
    display: flex;
    gap: 4rem;
    padding: 0 4rem;
}
.package-form-img {
    height: 100px;
    width: 150px;
    margin-top: 15px;
}
.package-form-img img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
}
.uploading-cover-image {
    font-size: 12px;
    color: red;
}
.package-form-location-details {
    padding: 1.5rem 4rem;
}
.package-form-location-details h2 {
    color: #001849;
    font-size: 16px;
    margin-bottom: 10px;
}
.package-form-country-place select {
    width: 100%;
    padding: 10px 15px;
    font-size: 16px;
    border: 1.5px solid rgba(35, 35, 35, 0.2);
    outline: none;
    border-radius: 20px;
    background-color: #fff;
    appearance: none; 
    -webkit-appearance: none; 
    -moz-appearance: none; 
    background-image: url('/public/selectdropicon.svg'); 
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 15px !important;
    cursor: pointer;
}
.package-form-country-place {
    display: flex;
    gap: 4rem;
}
.package-form-from-country {
    width: 100%;
}
.package-form-inclusion-exclusion {
    padding: 1rem 4rem;
}
.package-form-inclusion-exclusion h2 {
    color: #001849;
    font-size: 16px;
    margin-bottom: 10px;
}
.package-form-inclusion-exclusion-container {
    display: flex;
    gap: 4rem;
    justify-content: space-between;
}
.package-form-inclusion {
    width: 50%;
}
.package-form-inclusion-exclusion input {
    border: 1.5px solid rgba(35, 35, 35, 0.2);
    background: #fff;
    outline: none;
    padding: 10px 20px;
    border-radius: 20px;
    width: 100%;
}
.display-none {
    display: none;
}
.package-form-inclusion-exclusion-container p {
    cursor: pointer;
}
.package-form-inclusion input {
    border: 1.5px solid rgba(35, 35, 35, 0.2);
    background: #fff;
    outline: none;
    padding: 10px 20px;
    border-radius: 20px;
    width: 100%;
    margin-bottom: 10px;
}
.package-form-inclusion-exclusion-container span {
    color: #8BC4FF;
    font-size: 20px;
    font-weight: 600;
}
.package-form-cancellation-polocy textarea {
  overflow: hidden;
}
.package-form-itinerary-container {
    padding: 1.5rem 4rem;
}
.package-form-itinerary-container h2 {
    color: #001849;
    font-size: 16px;
    margin-bottom: 10px;
}
.package-form-itinerary h3 {
   color: #00213A;
   margin-bottom: 10px;
   font-size: 14px;
}
.package-form-itinery input {
    border: 1.5px solid rgba(35, 35, 35, 0.2);
    background: #fff;
    outline: none;
    padding: 10px 20px;
    border-radius: 20px;
    width: 100%;
    margin-bottom: 10px;
}
.package-form-price {
    padding: 1.5rem 4rem;
}
.package-form-price h2 {
    color: #001849;
    font-size: 16px;
    margin-bottom: 10px;
}
.package-form-price-value {
    display: flex;
    gap: 1rem;
}
.package-form-price-value select {
    width: 10%;
    padding: 5px 15px;
    font-size: 16px;
    border: 1.5px solid rgba(35, 35, 35, 0.2);
    outline: none;
    border-radius: 20px;
    background-color: #fff;
    appearance: none; 
    -webkit-appearance: none; 
    -moz-appearance: none; 
    background-image: url('/public/selectdropicon.svg'); 
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 15px !important;
    cursor: pointer;
}
.package-form-price-value input {
    border: 1.5px solid rgba(35, 35, 35, 0.2);
    background: #fff;
    outline: none;
    padding: 10px 20px;
    border-radius: 20px;
}
.package-form-back-next-btn {
    padding: 1.5rem 4rem;
    display: flex;
    gap: 2rem;
}
.package-form-back-next-btn button {
    border: 1px solid #2E90FA;
    outline: none;
    padding: 5px 25px;
    border-radius: 20px;
    font-weight: 500;
    background: none;
    cursor: pointer;
}
.package-form-back-next-btn #back {
    color: #2E90FA;
    border: 1px solid #2E90FA;
    padding: 4px 25px;
    border-radius: 20px;
    cursor: pointer;
}
.package-form-back-next-btn #next {
    color: #fff;
    background: #2E90FA;
}
.inclusion-input-delete-btn {
    display: flex;
    gap: 10px;
    align-items: center;
}
.inclusion-input-delete-btn button {
    background: none;
    outline: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}
/* ---------------------------------Create Attraction Form----------- */
.package-form-from-country input {
    border: 1.5px solid rgba(35, 35, 35, 0.2);
    background: #fff;
    outline: none;
    padding: 10px 20px;
    border-radius: 20px;
    width: 100%;
}
#landcombos-location-delete {
    font-size: 30px;
    font-weight: 600;
    position: absolute;
    margin-left: 1rem;
    margin-top: 1rem;
    cursor: pointer;
}
.package-booking-mustcarry {
    padding: 1rem 4rem;
}
.package-booking,.package-mustcarry {
    display: flex;
    align-items: baseline;
}
.package-booking-mustcarry p {
   font-size: 12px;
   color: #848484;
}