.success{
    margin-top: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    
}
.success-block {
    box-shadow: 0 0 8px green;
    margin: auto;
    text-align: center;
    padding: 4rem 2rem;
    border-radius: 0.8rem;
}
.success-block h2 {
    color: green;
}
.success-block p,h4 {
    color: #575252;
}
.success-block button {
    outline: none;
    border: none;
    box-shadow: none;
    background-color: blue;
    padding: 0.6rem 1rem;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}
.fail-block {
    box-shadow: 0 0 8px red;
    margin: auto;
    text-align: center;
    padding: 4rem 2rem;
    border-radius: 0.8rem;
}
.fail-block h2 {
    color: red;
}
.fail-block p {
    color: #575252;
}
.fail-block button {
    outline: none;
    border: none;
    box-shadow: none;
    background-color: blue;
    padding: 0.6rem 1rem;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}