.revenue-all-details {
    padding: 1rem;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    gap: 10px;
}
.revenue-agent {
    display: flex;
    gap: 10px;
}
.revenue h4 {
    color:#718EBF;
}
.revenue h2 {
    font-size: 16px;
}
.revenue p {
    font-size: 10px;
}