.add-country-name select {
    /* border: 1.5px solid skyblue;
    outline: none;
    border-radius: 20px;
    padding: 2px 10px;
    width: 100%; */
    width: 100%;
    padding: 5px 15px;
    font-size: 16px;
    border: 1.5px solid skyblue;
    outline: none;
    border-radius: 20px;
    background-color: #fff;
    appearance: none; 
    -webkit-appearance: none; 
    -moz-appearance: none; 
    background-image: url('/public/selectdropicon.svg'); 
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 15px !important;
    cursor: pointer;
}