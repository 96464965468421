.agent-markup-input input {
   outline: none;
   border: 1.5px solid #061b42;
   padding: 5px 5px 5px 15px;
   border-radius: 10px;
}
.agent-markup-btn {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.agent-markup-btn button {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    color: #061b42;
    font-weight: 700;
    font-size: 20px;
}