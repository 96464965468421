.login-container {
    background: rgb(255, 255, 255, 0.8);
    padding:1rem 5rem 4rem 5rem;
    border-radius: 20px;
    text-align: center;
    border: 1.5px solid skyblue;
}
#login-form {
    border: none !important;
    box-shadow: none !important;
    background: transparent;
}
#login-form-input {
    border-radius: 20px;
    outline: 1px solid skyblue;
    background: rgb(255, 255, 255, 0.6);
}
#login-form-btn {
    border-radius: 20px;
}